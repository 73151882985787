import { mapSuccessIndication } from '../../core';
import { ApiCompleteGeneratedAccountRequest, CompleteGeneratedAccountRequestInterface } from '../interfaces';

export function mapCompleteGeneratedAccountRequest(
    request: CompleteGeneratedAccountRequestInterface
): ApiCompleteGeneratedAccountRequest {
    return {
        current_password: request.temporaryPassword,
        new_password: request.newPassword,
        recovery_email_address: {
            email_address: request?.recoveryEmail || null,
        },
        mobile_telephone_number: {
            telephone_number: request.phone,
        },
        opt_out_indication: mapSuccessIndication(request.optOut),
    };
}
