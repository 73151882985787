<div class="address-autocomplete">
    <input
        class="form-control"
        [id]="name"
        e2e="address-placeholder"
        [name]="name"
        [disabled]="disabled"
        (keyup)="onChange($event)"
        (keyup.backspace)="onBackspace()"
        (keydown.delete)="preventDelete($event)"
        autocomplete="off"
        type="text"
        placeholder="Straße und Hausnr. (eintragen)"
        #addressIdInputRef
    />
    <ul class="address-autocomplete__options" *ngIf="autocompleteOptions" #optionsRef>
        <li class="address-autocomplete__list-item" *ngFor="let item of autocompleteOptions">
            <a
                tabindex="0"
                class="address-autocomplete__option"
                e2e="address-autocomplete__option"
                (click)="onAddressSelection(item.Id, item)"
            >
                <span>
                    <span
                        *ngIf="item.Text"
                        class="address-autocomplete__text"
                        [innerHTML]="item.Text | insHighlight: searchQuery"
                    ></span>
                    <span
                        *ngIf="item.Description"
                        class="address-autocomplete__subtext"
                        [innerHTML]="item.Description | insHighlight: searchQuery"
                    ></span>
                </span>
                <svg class="svg-icon svg-icon--xs" *ngIf="item.Type !== 'Address'">
                    <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                </svg>
            </a>
        </li>
    </ul>
</div>
