<ins-modal-title>Cookie Einstellungen</ins-modal-title>
<ins-modal-content>
    <p>
        InShared verwendet Cookies, um Ihnen einen besseren und persönlicheren Service zu bieten, wenn Sie mit uns in
        Kontakt stehen, zum Beispiel über unsere Website, über soziale Medien oder über Anzeigen auf anderen Websites.
    </p>
    <p>
        Zu diesem Zweck analysieren und kombinieren wir Ihr Surfverhalten mit den personenbezogenen Daten, die Sie
        online hinterlassen und die bei uns bekannt sind. Wir arbeiten dabei mit anderen Cookie-Anbietern auch außerhalb
        der EU zusammen. Erfahren Sie mehr über
        <a href="/{{appRoutesEnum.Cookies}}" (click)="openLink(appRoutesEnum.Cookies)">Cookies</a>
        oder lesen Sie unsere Datenschutzerklärung
        <!-- prettier-ignore -->
        <span><a href="/{{appRoutesEnum.Privacy}}" (click)="openLink(appRoutesEnum.Privacy)">„Datenschutz (Webseite)“</a>.</span>
    </p>

    <div *ngIf="toggle.explanation" class="cookie-explanation">
        <h3>Was sind Cookies?</h3>
        <p>
            InShared verwendet Cookies auf dieser Website. Ein Cookie ist eine kleine Datei, die Informationen enthält
            und auf Ihrem Computer gespeichert wird. Dadurch können wir Sie (bzw. Ihren Computer) beim nächsten Besuch
            wiedererkennen.
        </p>

        <h3>Wofür verwendet InShared Cookies?</h3>
        <p>
            Die Nutzerdaten, die wir durch den Einsatz von Cookies erhalten, verwenden wir für die Analyse unserer
            Website. Darüber hinaus setzen wir die gewonnenen Informationen dazu ein, unsere Website so weit wie möglich
            an Ihre persönlichen Präferenzen anzupassen. Ferner können wir so Ihr Interesse an bestimmten Seiten unserer
            Website berücksichtigen, wenn wir Ihnen Informationen über unsere Produkte und Dienstleistungen zusenden.
        </p>

        <h3>Cookies von Drittanbietern</h3>
        <p>
            Cookies werden auch dazu genutzt, um Ihnen gezielt Angebote auf Websites von Drittanbietern anzuzeigen.
            Diese werden uns bereitgestellt und von InShared platziert. Um das Resultat dieser Online-Anzeigen auf
            InShared.de und auf Websites von Drittanbietern zu messen und zu analysieren, arbeitet InShared mit
            spezialisierten Unternehmen zusammen. InShared kann diese Analysedaten nutzen, um Sie über eventuell für Sie
            interessante Angebote oder Produkte zu informieren. Zu Cookies von Social-Media-Anbietern wie Facebook und
            Twitter sowie den dadurch erfassten Daten verweisen wir auf die Cookie-Erklärungen auf den Websites der
            entsprechenden Anbieter. Bitte beachten Sie, dass sich diese Erklärungen regelmäßig ändern können. InShared
            hat hierauf keinen Einfluss.
        </p>

        <h3>Cookies auf InShared.de</h3>
        <!-- PDF plaatsen (WIDL-193) -->
        <!-- prettier-ignore -->
        <p>
            Es gibt verschiedene Arten von Cookies. Eine Übersicht aller von InShared und von Drittanbietern über unsere
            Website gesetzten Cookies finden Sie in der
            <a [href]="documentsEnum.CookieOverview" target="_blank">Cookie-Übersicht</a>
            . Hier erfahren Sie auch, zu welchen Zwecken diese Cookies verwendet werden und wie lange sie gespeichert
            bleiben. Auf InShared können Sie
            <a [routerLink]="['/', appRoutesEnum.Contact, { outlets: { modal: modalRoutesEnum.CookieSettings } }]" rel="noindex nofollow">
                selbst einstellen, welche Cookies Sie akzeptieren</a>.
        </p>

        <h3>Datenschutz</h3>
        <!-- prettier-ignore -->
        <p>
            Wir haben alle notwendigen Maßnahmen ergriffen, um Ihren Besuch und die Nutzung unserer Website so sicher
            wie möglich zu machen und Missbrauch zu verhindern. Weitere Informationen über den Umgang unserer
            Muttergesellschaft Achmea mit Ihren Daten finden Sie in unserer
            <a (click)="closeWithoutApproval()" [routerLink]="appRoutesEnum.Privacy">Datenschutzerklärung</a>.
        </p>

        <h3>Löschung von Cookies</h3>
        <p>
            Möchten Sie der Verwendung von Cookies widersprechen? In den Einstellungen Ihres Internet-Browsers können
            Sie Cookies aktivieren, deaktivieren und löschen. Das müssen Sie allerdings selbst vornehmen, da Cookies auf
            Ihrem Computer gespeichert werden. Eine Erklärung über die Anpassung Ihrer Cookie-Einstellungen finden Sie
            in der „Hilfe“-Funktion Ihres Browsers. Unter
            <a href="https://www.youronlinechoices.com/de" target="_blank">www.youronlinechoices.com/de</a>
            können Sie Cookies von Dritten zentral löschen, um ein erneutes Setzen dieser Cookies auf anderen Websites
            zu unterbinden.
        </p>
    </div>

    <h3>Stimmen Sie zu?</h3>
    <p>
        Dann erteilen Sie Ihre Einwilligung für das Setzen von Analyse-, Affiliate-, Feedback-, Werbe- und
        Personalisierungs-Cookies sowie für die Verarbeitung Ihrer personenbezogenen Daten. Wenn Sie das nicht möchten,
        können Sie Ihre Cookie-Einstellungen ändern oder allein mit notwendigen Cookies fortfahren.
    </p>

    <div class="btn-group btn-group--mobile-full cookie-settings__buttons">
        <button
            (click)="closeWithApproval()"
            *ngIf="!cookieForm.value.cookieSettings"
            class="btn btn--primary"
            type="button"
        >
            Zustimmen
        </button>
        <button (click)="toggleSettings()" class="btn btn--secondary btn--dropdown" type="button">
            Einstellungen ändern
            <svg *ngIf="toggle.cookieSettings" class="btn__icon svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#pijl-up-bold"></use>
            </svg>
            <svg *ngIf="!toggle.cookieSettings" class="btn__icon svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#pijl-down-bold"></use>
            </svg>
        </button>
    </div>

    <div *ngIf="toggle.cookieSettings" class="cookie-settings__panel">
        <p>
            Unsere Website verwendet
            <a [href]="documentsEnum.CookieOverview" target="_blank">3 Arten von Cookies</a>
            . Sie können selbst auswählen, welche Cookies Sie akzeptieren. Ihre Auswahl können Sie über den Link
            „Cookie-Einstellungen ändern“ unten auf der Website jederzeit anpassen. Da die Website ohne funktionale
            Cookies nicht ordnungsgemäß funktioniert, können diese nicht deaktiviert werden.
        </p>
        <h3>Einstellungen ändern</h3>
        <form
            (ngSubmit)="onSubmit()"
            [formGroup]="cookieForm"
            class="form-horizontal cookie-settings__form"
            role="form"
        >
            <cyno-field [inline]="false" [showSuccess]="false">
                <cyno-radio formControlName="cookieSettings" id="cookiesettings_alle" value="all">
                    Ich will funktionale, analytische, Affiliate-, Feedback-, Werbe- und Personalisierungs-Cookies
                    (Alle)
                </cyno-radio>
            </cyno-field>
            <cyno-field [inline]="false" [showSuccess]="false">
                <cyno-radio formControlName="cookieSettings" id="cookiesettings_extensive" value="extensive">
                    Ich will funktionale, Affiliate- und analytische Cookies (Erweitert)
                </cyno-radio>
            </cyno-field>
            <cyno-field [inline]="false" [showSuccess]="false">
                <cyno-radio formControlName="cookieSettings" id="cookiesettings_basic" value="basic">
                    Ich will nur funktionale Cookies (Basis)
                </cyno-radio>
            </cyno-field>

            <ng-container *ngIf="cookieForm.value.cookieSettings">
                <ins-card-rack>
                    <ins-card [borders]="true">
                        <ins-card-head>
                            <h4>Was wir tun</h4>
                        </ins-card-head>
                        <ins-card-content>
                            <ins-list-flat [svg]="true" icon="check">
                                <ins-list-flat-item>
                                    Notwendige Cookies setzen, damit diese Website gut funktioniert
                                </ins-list-flat-item>
                                <ins-list-flat-item>Anonyme Statistiken erheben </ins-list-flat-item>
                                <ins-list-flat-item>Beitragsberechnungen und Anmeldungen erfassen</ins-list-flat-item>
                                <ins-list-flat-item
                                    *ngIf="
                                        cookieForm.value.cookieSettings === 'extensive' ||
                                        cookieForm.value.cookieSettings === cookieSettingsEnum.All
                                    "
                                >
                                    Nutzerverhalten untersuchen
                                </ins-list-flat-item>
                                <ins-list-flat-item *ngIf="cookieForm.value.cookieSettings === cookieSettingsEnum.All">
                                    Personenbezogene Statistiken erheben
                                </ins-list-flat-item>
                                <ins-list-flat-item *ngIf="cookieForm.value.cookieSettings === cookieSettingsEnum.All">
                                    Maßgeschneiderte Informationen anzeigen
                                </ins-list-flat-item>
                                <ins-list-flat-item *ngIf="cookieForm.value.cookieSettings === cookieSettingsEnum.All">
                                    " Personalisierte Anzeigen auf anderen Websites und in den sozialen Netzwerken auf
                                    Basis Ihres Nutzungsverhaltens schalten"
                                </ins-list-flat-item>
                            </ins-list-flat>
                        </ins-card-content>
                    </ins-card>
                    <ins-card *ngIf="cookieForm.value.cookieSettings !== cookieSettingsEnum.All" [borders]="true">
                        <ins-card-head>
                            <h4>Was wir nicht tun</h4>
                        </ins-card-head>
                        <ins-card-content>
                            <ins-list-flat [svg]="true" icon="check">
                                <ins-list-flat-item
                                    *ngIf="cookieForm.value.cookieSettings === cookieSettingsEnum.Basis"
                                >
                                    Nutzerverhalten untersuchen
                                </ins-list-flat-item>
                                <ins-list-flat-item>Personenbezogene Statistiken erheben</ins-list-flat-item>
                                <ins-list-flat-item>Maßgeschneiderte Informationen anzeigen</ins-list-flat-item>
                                <ins-list-flat-item>
                                    Personalisierte Anzeigen auf Basis Ihres Nutzungsverhaltens schalten
                                </ins-list-flat-item>
                            </ins-list-flat>
                        </ins-card-content>
                    </ins-card>
                </ins-card-rack>
            </ng-container>
        </form>
    </div>
</ins-modal-content>

<ng-container *ngIf="cookieForm.value.cookieSettings">
    <div *ngIf="cookieForm.value.cookieSettings === cookieSettingsEnum.All" alignment="start" modal-actions>
        <div class="cookie-settings__button-footer">
            <button (click)="onSubmit()" class="btn btn--primary" id="footer-button-right" type="submit">
                Ich akzeptiere Cookies
            </button>
        </div>
    </div>
    <div *ngIf="cookieForm.value.cookieSettings !== cookieSettingsEnum.All" alignment="end" modal-actions>
        <div class="cookie-settings__button-footer">
            <button (click)="onSubmit()" class="btn btn--primary" id="footer-button-left" type="submit">
                Ich akzeptiere Cookies
            </button>
        </div>
    </div>
</ng-container>
