<ins-smart-tips-card [smartTipsCarousel]="smartTipsCarousel">
    <a class="card__link" [routerLink]="['/', appRoutesEnum.SmartTips, smartTipsEnum.HoheStrafenImStrassenverkehr]">
        <ins-card-image>
            <ins-banner
                [image]="smartTipsImageEnum.HoechstenBussgelder"
                imageAlt="Geldscheine von einem Bußgeld stecken in einer Polizeimütze."
                imageTitle="Bußgeldbescheid bekommen? Hoffentlich keine der folgenden Strafen, besser Sie umfahren hohe Bußgelder im Verkehr!"
                size="small"
            ></ins-banner>
        </ins-card-image>
        <ins-card-head>
            <h3>Nicht erschrecken: Das sind die höchsten Bußgelder im Straßenverkehr</h3>
        </ins-card-head>
        <ins-card-content>
            <p class="card__content-text">
                Es ist der Schrecken eines jeden Führerscheinbesitzers: ein Umschlag mit einem Bußgeldbescheid von der
                Verwaltungsbehörde. Ein Albtraum vor allem dann, wenn es sich um eine hohe Geldsumme handelt.
            </p>
        </ins-card-content>
        <ins-card-footer>
            <ins-read-article [smartTip]="smartTipsEnum.HoheStrafenImStrassenverkehr"></ins-read-article>
        </ins-card-footer>
    </a>
</ins-smart-tips-card>
