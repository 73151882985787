<ins-smart-tips-card [smartTipsCarousel]="smartTipsCarousel" [horizontal]="horizontal">
    <a class="card__link" [routerLink]="['/', appRoutesEnum.SmartTips, smartTipsEnum.FragenZurGrossenInspektion]">
        <ins-card-image>
            <ins-banner
                [image]="smartTipsImageEnum.GrosseInspektion"
                imageAlt="Ein Stethoskop untersucht ein Auto, um Probleme zu inspizieren."
                imageTitle="Finden Sie die Antworten auf die 8-häufigsten Fragen, warum eine große Inspektion gut für Ihr Auto ist."
                size="small"
            ></ins-banner>
        </ins-card-image>
        <ins-card-head>
            <h3>Die häufigsten Fragen zur großen Inspektion</h3>
        </ins-card-head>
        <ins-card-content>
            <p class="card__content-text">
                Warum muss Ihr Auto regelmäßig zur großen Inspektion? Was ist eigentlich der Unterschied zwischen großer
                und kleiner Kfz-Inspektion? Und wie viel kostet eine große Inspektion? Die Antworten auf die wichtigsten
                Fragen.
            </p>
        </ins-card-content>
        <ins-card-footer>
            <ins-read-article [smartTip]="smartTipsEnum.FragenZurGrossenInspektion"></ins-read-article>
        </ins-card-footer>
    </a>
</ins-smart-tips-card>
