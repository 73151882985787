import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { KeyCodeEnum } from '../../../core';
import { WucInputBaseComponent } from '../input-base.component';
import { TemporaryDateService } from '../input-date-picker/temporary-date.service';
import { InputModeEnum } from '../input-mode.enum';
import { InputInterface } from '../input.interface';
import { InputService } from '../input.service';

@Component({
    selector: 'wuc-input-date',
    templateUrl: 'input-date.component.html',
    styleUrls: ['input-date.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputDateComponent }],
})
export class WucInputDateComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    /* Placeholder */
    @Input()
    public set placeholder(placeholder: string) {
        this.setPlaceholder(placeholder);
        this._placeholder = placeholder;
        this.updateInactiveControlValue();
    }

    public get placeholder(): string {
        return this._placeholder;
    }

    /* Input elementRef's */
    @ViewChild('inputDay')
    public inputDayElement!: ElementRef<HTMLInputElement>;

    @ViewChild('inputMonth')
    public inputMonthElement!: ElementRef<HTMLInputElement>;

    @ViewChild('inputYear')
    public inputYearElement!: ElementRef<HTMLInputElement>;

    public isDisabled: boolean = false;
    public _placeholder: string = '';
    public placeholderDay: string = '';
    public placeholderMonth: string = '';
    public placeholderYear: string = '';
    public valueDay: string = '';
    public valueMonth: string = '';
    public valueYear: string = '';
    public dateSeparator: string = '-';
    public visualDateSeparator: string = '-';
    public hasFocus: boolean = false;
    public isActive: boolean = false;
    public inactiveControl: FormControl<string | null> = new FormControl<string | null>(null);

    public onChange!: (_: string) => void;
    public onTouched!: () => void;

    private readonly allowedCharactersRexExp: RegExp = /^\d+$/i;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        this.inputmode = InputModeEnum.Numeric;
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        const [year, month, day] = value?.split(this.dateSeparator) || [];
        this.valueDay = day || '';
        this.valueMonth = month || '';
        this.valueYear = year || '';
        this.updateInactiveControlValue();
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: (_: string) => void): void {
        this.onChange = fn;
    }

    // Mark control as touched; View => API
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.inactiveControl.disable();
        } else {
            this.inactiveControl.enable();
        }
    }

    /* Prevent user from entering text or invalid day (> 31) */
    public onBeforeInputDay(event: InputEvent): void {
        if (!event.data) {
            return;
        }

        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
            return;
        }

        const newValue = InputService.replaceSelection(this.inputDayElement.nativeElement, event.data);

        if (Number(newValue) > 31) {
            event.preventDefault();
        }
    }

    /* Prevent user from entering text or invalid month (> 12) */
    public onBeforeInputMonth(event: InputEvent): void {
        if (!event.data) {
            return;
        }

        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
            return;
        }

        const newValue = InputService.replaceSelection(this.inputMonthElement.nativeElement, event.data);

        if (Number(newValue) > 12) {
            event.preventDefault();
        }
    }

    /* Prevent user from entering text */
    public onBeforeInputYear(event: InputEvent): void {
        if (!event.data) {
            return;
        }
        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
            return;
        }
    }

    public onInputDay(event: Event): void {
        const inputEvent = event as InputEvent;
        const inputElement = event.target as HTMLInputElement;
        if (inputEvent.inputType === 'insertText') {
            const asNumber = Number(inputElement.value);
            /* If value is 4 trough 9, this must be the day number, set focus to month input */
            if (asNumber >= 4 && asNumber <= 9) {
                this.setFocusAndCursorAtStart(this.inputMonthElement);
            }
        }
        /* If user entered 2 numbers and cursor is at end, set focus to month input */
        if (inputElement.value.length == 2 && inputElement.selectionStart == 2) {
            this.setFocusAndCursorAtStart(this.inputMonthElement);
        }
        this.emitValueChange();
    }

    public onInputMonth(event: Event): void {
        const inputEvent = event as InputEvent;
        const inputElement = event.target as HTMLInputElement;
        if (inputEvent.inputType === 'insertText') {
            const asNumber = Number(inputElement.value);
            /* If value is 2 trough 9, this must be the month number, set focus to year input */
            if (asNumber >= 2 && asNumber <= 9) {
                this.setFocusAndCursorAtStart(this.inputYearElement);
            }
        }
        /* If user entered 2 numbers and cursor is at end, set focus to year input */
        if (inputElement.value.length == 2 && inputElement.selectionStart == 2) {
            this.setFocusAndCursorAtStart(this.inputYearElement);
        }
        this.emitValueChange();
    }

    public onInputYear(event: Event): void {
        const inputEvent = event as InputEvent;

        if (inputEvent.inputType === 'insertText') {
            const inputElement = event.target as HTMLInputElement;
            let prefix: string | null = null;
            const asNumber = Number(inputElement.value);
            if (asNumber <= 9 || asNumber === 19 || asNumber === 20) {
                prefix = null;
            } else if (asNumber <= TemporaryDateService.currentYearWithoutCentury) {
                prefix = '20';
            } else if (asNumber <= 99) {
                prefix = '19';
            }
            if (prefix) {
                this.valueYear = `${prefix}${asNumber}`;
            }
        }
        this.emitValueChange();
    }

    public onKeyDownDay(event: KeyboardEvent): void {
        const inputElement = event.target as HTMLInputElement;
        if (
            inputElement.selectionEnd === inputElement.value.length &&
            (event.key === KeyCodeEnum.ArrowRight || (event.key === this.dateSeparator && inputElement.value))
        ) {
            this.setFocusAndCursorAtStart(this.inputMonthElement);
            event.preventDefault();
        }
    }

    public onKeyDownMonth(event: KeyboardEvent): void {
        const inputElement = event.target as HTMLInputElement;
        if (
            (event.key === KeyCodeEnum.ArrowLeft && inputElement.selectionStart === 0) ||
            (event.key === KeyCodeEnum.Backspace && !inputElement.value)
        ) {
            this.setFocusAndCursorAtEnd(this.inputDayElement);
            event.preventDefault();
        }
        if (
            inputElement.selectionEnd === inputElement.value.length &&
            (event.key === KeyCodeEnum.ArrowRight || (event.key === this.dateSeparator && inputElement.value.length))
        ) {
            this.setFocusAndCursorAtStart(this.inputYearElement);
            event.preventDefault();
        }
    }

    public onKeyDownYear(event: KeyboardEvent): void {
        const inputElement = event.target as HTMLInputElement;
        if (
            (event.key === KeyCodeEnum.ArrowLeft && inputElement.selectionStart === 0) ||
            (event.key === KeyCodeEnum.Backspace && !inputElement.value)
        ) {
            this.setFocusAndCursorAtEnd(this.inputMonthElement);
            event.preventDefault();
        }
    }

    /* If focus is lost, format day */
    public onFocusOutDay(): void {
        if (this.valueDay.length === 1) {
            this.valueDay = `0${this.valueDay}`;
            this.emitValueChange();
        }
    }

    /* If focus is lost, format month */
    public onFocusOutMonth(): void {
        if (this.valueMonth.length === 1) {
            this.valueMonth = `0${this.valueMonth}`;
            this.emitValueChange();
        }
    }

    /* If focus is lost, format year */
    public onFocusOutYear(): void {
        if (this.valueYear) {
            const asNumber = Number(this.valueYear);
            if (asNumber <= 9) {
                this.valueYear = `200${asNumber}`;
                this.emitValueChange();
            }

            if (asNumber >= 10 && asNumber <= 99) {
                if (asNumber <= TemporaryDateService.currentYearWithoutCentury) {
                    this.valueYear = `20${asNumber}`;
                } else {
                    this.valueYear = `19${asNumber}`;
                }
                this.emitValueChange();
            }
        }
    }

    public onInputFillerClick(): void {
        this.setFocus();
    }

    public onActivate(): void {
        if (!this.isDisabled) {
            this.isActive = true;
            setTimeout(() => {
                this.setFocus();
            });
        }
    }

    public onFocusIn(): void {
        this.hasFocus = true;
    }

    public onFocusInMonth(): void {
        this.onFocusIn();
        if (!this.valueDay) {
            this.setFocusAndCursorAtStart(this.inputDayElement);
        }
    }

    public onFocusInYear(): void {
        this.onFocusIn();
        if (!this.valueMonth) {
            this.setFocusAndCursorAtStart(this.inputMonthElement);
        }
    }

    public onClickedOutside(): void {
        if (this.hasFocus) {
            this.onTouched();
            this.hasFocus = false;
            this.isActive = false;
        }
    }

    public override setFocus(): void {
        this.hasFocus = true;
        if (this.valueYear || this.valueMonth) {
            this.setFocusAndCursorAtEnd(this.inputYearElement);
        } else if (this.valueDay) {
            this.setFocusAndCursorAtEnd(this.inputMonthElement);
        } else {
            this.setFocusAndCursorAtEnd(this.inputDayElement);
        }
    }

    private setPlaceholder(placeholder: string): void {
        if (!/^[a-z]{2}[^a-z][a-z]{2}[^a-z][a-z]{4}$/i.test(placeholder)) {
            console.error(`Invalid date placeholder: ${placeholder}`);
            return;
        }

        this.visualDateSeparator = placeholder[2];
        const parts = placeholder.split(this.visualDateSeparator);
        this.placeholderDay = parts[0];
        this.placeholderMonth = parts[1];
        this.placeholderYear = parts[2];
    }

    private emitValueChange(): void {
        let newValue: string = [this.valueYear, this.valueMonth, this.valueDay].join(this.dateSeparator);
        if (newValue.length !== 10) {
            newValue = '';
        }
        if ((!this.ngControl.value && newValue) || (this.ngControl.value && this.ngControl.value !== newValue)) {
            this.onChange(newValue);
            this.onTouched();
            this.updateInactiveControlValue();
        }
    }

    private setFocusAndCursorAtStart(element: ElementRef<HTMLInputElement>): void {
        this.setFocusAndCursor(element, true);
    }

    private setFocusAndCursorAtEnd(element: ElementRef<HTMLInputElement>): void {
        this.setFocusAndCursor(element, false);
    }

    private setFocusAndCursor(element: ElementRef<HTMLInputElement>, atStart: boolean): void {
        element.nativeElement.focus();
        const endIndex = element.nativeElement.value.length + 1;
        const cursorIndex = atStart ? 0 : endIndex;
        element.nativeElement.setSelectionRange(cursorIndex, endIndex);
    }

    private updateInactiveControlValue(): void {
        this.inactiveControl.setValue(
            !!this.ngControl.value
                ? this.valueDay + this.visualDateSeparator + this.valueMonth + this.visualDateSeparator + this.valueYear
                : null
        );
    }
}
