import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { IconUiIcon, IconUiType } from '../icon-ui';

@Component({
    selector: 'wuc-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss'],
})
export class WucButtonComponent {
    @Output() public buttonClick: EventEmitter<Event> = new EventEmitter();

    @Input() public type: 'primary' | 'secondary' | 'clean' = 'primary';
    @Input() public small: boolean = false;
    @Input() public icon?: IconUiIcon;
    @Input() public iconRight?: IconUiIcon;
    @Input() public iconLeft?: IconUiIcon;
    @Input() public iconType?: IconUiType;
    @Input() public disabled: boolean = false;
    @Input() public title: string = '';
    @Input() public tabindex?: number;
    @HostBinding('class.wuc-button--full-width-container') @Input() public isFullWidth: boolean = false;
    @Input() public isSubmitButton: boolean = false;

    public onClick(event?: Event): void {
        if (this.disabled) {
            return;
        }

        this.buttonClick.emit(event);
    }
}
