export enum SmartTipsEnum {
    AutoFitFurDenWinter = 'auto-fit-fur-den-winter',
    AutoSicherheitHerbstWinter = 'auto-sicherheit-herbst-winter',
    AutoSommerfitMachen = 'auto-sommerfit-machen',
    AutoVerleihenIstVersichert = 'auto-verleihen-ist-versichert',
    AutofahrenBeiGefahrlichemWetter = 'autofahren-bei-gefahrlichem-wetter',
    AutofahrenUndGeldsparen = 'autofahren-und-geldsparen',
    DeutscheLiebenIhrAuto = 'deutsche-lieben-ihr-auto',
    EinfadelnAuffahrtAutobahn = 'einfadeln-auffahrt-autobahn',
    EnergieEffizienzLabelAuto = 'energie-effizienz-label-auto',
    FaktenKlimaanlageAuto = '11-fakten-klimaanlage-auto',
    FallenMietautoImUrlaub = 'fallen-mietauto-im-urlaub',
    FehlerEiskratzenAutoscheiben = 'fehler-eiskratzen-autoscheiben',
    FehlerVermeidenAutoWaschanlage = 'fehler-vermeiden-auto-waschanlage',
    FragenAntwortenHauptuntersuchungAuto = 'fragen-antworten-hauptuntersuchung-auto',
    FragenZurGrossenInspektion = 'fragen-zur-großen-inspektion',
    FehlendeAnhangerkupplungElektroauto = 'fehlende-anhangerkupplung-elektroauto',
    HaufigeFehlerBeimTanken = '7-haufige-fehler-beim-tanken',
    HoheStrafenImStrassenverkehr = 'hohe-strafen-im-strassenverkehr',
    KlimaanlageBeiHeuschnupfenEinschalten = 'klimaanlage-bei-heuschnupfen-einschalten',
    KontrolleWichtigsteFlussigkeitenAuto = 'kontrolle-wichtigste-flussigkeiten-auto',
    LegendenAutoBatterie = '8-legenden-auto-batterie',
    MitSicherheitAutopannenVermeiden = 'mit-sicherheit-autopannen-vermeiden',
    MythenElektrischesAutoFahren = 'mythen-elektrisches-auto-fahren',
    NutzlichesWissenAutoschlussel = 'nutzliches-wissen-autoschlussel',
    PraktischeChecklisteAutokauf = 'praktische-checkliste-autokauf',
    ReichweiteElektroautoErhohen = 'reichweite-elektroauto-erhohen',
    RichtigesVerhaltenBeiStau = 'richtiges-verhalten-bei-stau',
    SicherUnterwegsBeiEisGlatte = 'sicher-unterwegs-bei-eis-glatte',
    SichereAutoreisenMitKindern = 'sichere-autoreisen-mit-kindern',
    SicheresAutofahrenImSturm = 'sicheres-autofahren-im-sturm',
    StausMitAutoMeiden = 'staus-mit-auto-meiden',
    TippsAutoReisenAusland = 'tipps-auto-reisen-ausland',
    TippsAutoVorbereitenHu = 'tipps-auto-vorbereiten-hu',
    TippsAutounfalleVermeiden = 'tipps-autounfalle-vermeiden',
    TippsPreiswertTanken = '6-tipps-preiswert-tanken',
    TippsSpritsparen = '13-tipps-spritsparen',
    TippsVerhaltenBeiAutopanne = 'tipps-verhalten-bei-autopanne',
    VorteileZeitFurWinterreifen = '6-vorteile-zeit-fur-winterreifen',
    WallboxLadesauleElektroAuto = 'wallbox-ladesaule-elektro-auto',
    WannReifenErsetzenCheckliste = 'wann-reifen-ersetzen-checkliste',
    WichtigeFaktenSicherheitsgurt = 'wichtige-fakten-sicherheitsgurt',
    WissenswertesAutobahnDeutschland = 'wissenswertes-autobahn-deutschland',
    WissenswertesTankenAusland = 'wissenswertes-tanken-ausland',
    ParkenOhneStress = 'parken-ohne-stress',
    ArgerlicheSituationenImAuto = 'argerliche-situationen-im-auto',
    WichtigeDingeFursAuto = '6-wichtige-dinge-furs-auto',
    AutofahrenAblenkungenVermeiden  = 'autofahren-ablenkungen-vermeiden',
    WenigerStressBeimAutofahren = 'weniger-stress-beim-autofahren',
    WissenAutoHalterwechselVersicherungswechsel = 'wissen-auto-halterwechsel-versicherungswechsel',
}