import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ResponseInterface } from '../../core';
import {
    ApiForgotEmailRequest,
    ApiRecoveryMethodsResponse,
    CompleteGeneratedAccountRequestInterface,
    ForgotEmailRequestInterface,
    RecoveryMethodsRequestInterface,
    RecoveryMethodsResponseInterface,
    SendPasswordRequestInterface,
    UpdateTemporaryPasswordRequestInterface,
    ValidateGeneratedAccountRequestInterface,
    UpdateEmailAddressRequestInterface,
    ValidateGeneratedAccountResponseInterface,
} from '../interfaces';
import {
    mapForgotEmailRequest,
    mapRecoveryMethodsRequest,
    mapRecoveryMethodsResponse,
    mapSendPasswordRequest,
    mapUpdateTemporaryPasswordRequest,
    mapValidateGeneratedAccountRequest,
    mapValidateGeneratedAccountResponse,
    mapCompleteGeneratedAccountRequest,
    mapUpdateAccountPasswordRequest,
    mapUpdateEmailAddressRequest,
} from '../mappers';

const RESOURCES = {
    RECOVERY_METHODS: 'en-gb/relation-authorization/request/account-password',
    SEND_PASSWORD: 'en-gb/relation-authorization/provide/account-password',
    UPDATE_CREDENTIALS: 'en-gb/relation-authorization/modify/account-credentials',
    UPDATE_PASSWORD: 'en-gb/relation-authorization/modify/account-password',
    UPDATE_EMAIL: 'en-gb/relation-authorization/modify/emailaddress',
    REQUEST_EMAIL: 'en-gb/relation-contact/request/account-email_address',
    VALIDATE_GENERATED_ACCOUNT: 'en-gb/relation-authorization/validate/welcome_dialogue-postal_code_date_of_birth',
    COMPLETE_ACCOUNT: 'en-gb/relation-authorization/modify/welcome_dialogue-account-credentials',
};

@Injectable({
    providedIn: 'root',
})
export class AccountRecoveryApiService {
    constructor(private apiService: ApiService) {}

    public getRecoveryMethods$(request: RecoveryMethodsRequestInterface): Observable<RecoveryMethodsResponseInterface> {
        const payload = mapRecoveryMethodsRequest(request);

        return this.apiService
            .post$<ApiRecoveryMethodsResponse>(RESOURCES.RECOVERY_METHODS, payload)
            .pipe(map(mapRecoveryMethodsResponse));
    }

    public sendPassword$(request: SendPasswordRequestInterface): Observable<ResponseInterface> {
        const payload = mapSendPasswordRequest(request);

        return this.apiService.postResponse$(RESOURCES.SEND_PASSWORD, payload).pipe(map(ApiService.throwErrors));
    }

    public updateTemporaryPassword$(request: UpdateTemporaryPasswordRequestInterface): Observable<void> {
        const payload = mapUpdateTemporaryPasswordRequest(request);

        return this.apiService.post$<void>(RESOURCES.UPDATE_CREDENTIALS, payload);
    }

    public updatePassword$(password: string): Observable<void> {
        const payload = mapUpdateAccountPasswordRequest(password);

        return this.apiService.post$<void>(RESOURCES.UPDATE_PASSWORD, payload);
    }

    public updateEmail$(request: UpdateEmailAddressRequestInterface): Observable<void> {
        const payload = mapUpdateEmailAddressRequest(request);

        return this.apiService.post$<void>(RESOURCES.UPDATE_EMAIL, payload);
    }

    public forgotEmail$(request: ForgotEmailRequestInterface): Observable<ResponseInterface> {
        const payload: ApiForgotEmailRequest = mapForgotEmailRequest(request);

        return this.apiService.postResponse$(RESOURCES.REQUEST_EMAIL, payload).pipe(map(ApiService.throwErrors));
    }

    public validateGeneratedAccount$(
        request: ValidateGeneratedAccountRequestInterface
    ): Observable<ValidateGeneratedAccountResponseInterface> {
        const payload = mapValidateGeneratedAccountRequest(request);

        return this.apiService.postResponse$(RESOURCES.VALIDATE_GENERATED_ACCOUNT, payload).pipe(
            map(ApiService.throwErrors),
            map((response: ResponseInterface) => {
                const data = mapValidateGeneratedAccountResponse(response.data);
                if (!data.validationSuccessful) {
                    throw response.errors;
                }
                return data;
            })
        );
    }

    public completeGeneratedAccount$(request: CompleteGeneratedAccountRequestInterface): Observable<void> {
        const payload = mapCompleteGeneratedAccountRequest(request);

        return this.apiService.post$<any>(RESOURCES.COMPLETE_ACCOUNT, payload);
    }
}
