import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CookieConsent, NewModalRef } from 'outshared-lib';
import { DocumentsEnum } from '@app-de/core/enums/documents.enum';
import { StorageService } from '@app-de/core/storage/universal.inject';
import { AppRoutesEnum, ModalRoutesEnum } from '@app-de/routes';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing/salesfunnel-routing.routes';
import { WINDOW } from '@inshared/shared/util';

@Component({
    selector: 'ins-cookie-settings-wall-modal',
    templateUrl: 'cookie-settings-wall.modal.html',
    styleUrls: ['./cookie-settings-wall.modal.scss'],
})
export class CookieSettingsWallModal {
    public cookieForm: UntypedFormGroup = new UntypedFormGroup({
        cookieSettings: new UntypedFormControl('', [Validators.required]),
    });

    public toggle = {
        cookieSettings: false,
        explanation: false,
    };

    public cookieSettingsEnum = CookieConsent;
    public appRoutesEnum = AppRoutesEnum;
    public documentsEnum = DocumentsEnum;
    public modalRoutesEnum = ModalRoutesEnum;

    constructor(
        @Inject(StorageService) private storage: Storage,
        @Inject(WINDOW) private window: Window,
        public modalRef: NewModalRef<CookieSettingsWallModal>,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public onSubmit(): void {
        if (this.cookieForm.invalid) {
            return;
        }

        this.saveSettings(this.cookieForm.value.cookieSettings);
    }

    public toggleSettings(): void {
        this.toggle.cookieSettings = !this.toggle.cookieSettings;
        this.cookieForm.reset({
            cookieSettings: '',
        });
    }

    public toggleExplanation(): void {
        this.toggle.explanation = !this.toggle.explanation;
    }

    public closeWithoutApproval(): void {
        this.saveSettings(this.cookieSettingsEnum.Basic);
    }

    public closeWithApproval(): void {
        this.saveSettings(this.cookieSettingsEnum.All);
    }

    public openLink(link: string): void {
        this.saveSettings(this.cookieSettingsEnum.Basic, link);
    }

    private saveSettings(cookieSettings: string, link?: string): void {
        this.storage.setItem('ls.cookie.setting', cookieSettings);

        const currentURL = this.document.location.pathname;

        if (currentURL.includes(SalesfunnelRoutes.Activate)) {
            this.modalRef.close();
            return;
        }

        // After cookie is set, reload page, or go to link
        if (link) {
            this.window.location.href = '/' + link;
        } else {
            this.window.location.reload();
        }
    }
}
