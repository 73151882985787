<!-- TODO: WIDL-288 - Optout banner -->
<ins-my-zone-notifications-container></ins-my-zone-notifications-container>
<div class="container">
    <div class="myzone">
        <h1 e2e="MyInsurance">Meine Versicherungsmappe</h1>
    </div>
</div>

<section id="topbar" class="section--border section--vertical-spacing section--sticky section--desktop">
    <div class="container">
        <div class="myzone__container">
            <ins-sticky-bar class="myzone__sticky-bar">
                <ins-sticky-bar-first>
                    <ins-chatbot-entry-container></ins-chatbot-entry-container>
                </ins-sticky-bar-first>
            </ins-sticky-bar>
        </div>
    </div>
</section>

<section class="section section--content">
    <div class="container">
        <div class="wrapper">
            <div class="col-3 myzone__sidemenu_wrapper">
                <ins-sidemenu class="myzone__sidemenu"></ins-sidemenu>
                <ins-no-changes-possible-notification></ins-no-changes-possible-notification>
            </div>
            <div class="col-9 myzone__outlet">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>

<section id="insurance-control" class="section section--content section--grey">
    <div class="container">
        <ins-insurance-control-container></ins-insurance-control-container>
    </div>
</section>

<section id="wertvolle-tipps-und-wissenswertes" class="section section--content section--grey">
    <div class="container">
        <h2 class="text text--center">Wertvolle Tipps zur Vermeidung von Schäden</h2>
        <ins-news-carousel>
            <ins-autopanne-vermeiden-summary [smartTipsCarousel]="true"></ins-autopanne-vermeiden-summary>
            <ins-auto-reisezeit-summary [smartTipsCarousel]="true"></ins-auto-reisezeit-summary>
            <ins-grosse-inspektion-summary [smartTipsCarousel]="true"></ins-grosse-inspektion-summary>
            <ins-hoechsten-bussgelder-summary [smartTipsCarousel]="true"></ins-hoechsten-bussgelder-summary>
            <ins-tanken-fehler-summary [smartTipsCarousel]="true"></ins-tanken-fehler-summary>
            <ins-verhalten-autopanne-summary [smartTipsCarousel]="true" class="carousel__item--isLast"></ins-verhalten-autopanne-summary>
        </ins-news-carousel>

        <br/>
        <p class="text text--center">
            <a class="btn btn--primary" [routerLink]="['/', appRoutesEnum.SmartTips]">Alle Tipps lesen</a>
        </p>
    </div>
</section>

<!-- TODO: WIDL-292 - E-zine toevoegen -->
<!-- TODO: WIDL-293 - Verzkeringen plaatjes toevoegen -->

<ins-mobile-menu-my-zone></ins-mobile-menu-my-zone>
