<h3>Versicherungsdetails</h3>
<table class="table-data" *ngIf="product">
    <colgroup>
        <col width="33%"/>
        <col width="33%"/>
        <col width="33%"/>
    </colgroup>
    <tbody>
    <tr>
        <td>Beginndatum</td>
        <td>
            <ng-container *ngIf="product.start_date === datesEnum.NoDate">unbekannt</ng-container>
            <ng-container *ngIf="product.start_date !== datesEnum.NoDate">
                {{ product.start_date | dateFormat }}
            </ng-container>
        </td>
        <td class="text-right">
            <ins-panel-button
                *ngIf="canChangeStartDate"
                [target]="changeStartDate"
                openText="Ändern"
                closeText="Schließen"
            ></ins-panel-button>
        </td>
    </tr>
    </tbody>
</table>
<ins-panel-expandable direction="right" #changeStartDate="panel">
    <ins-change-start-date-insurance-container></ins-change-start-date-insurance-container>
</ins-panel-expandable>

<table class="table-data" *ngIf="product">
    <colgroup>
        <col width="33%"/>
        <col width="33%"/>
        <col width="33%"/>
    </colgroup>
    <tbody>
    <tr>
        <td>
            Enddatum
            <span *ngIf="product.end_date" insTooltip="Ab diesem Datum sind Sie nicht mehr versichert.">
                    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#informatie"></use></svg>
                </span>
        </td>
        <td [attr.colspan]="canTerminate || canCancelTermination ? 1 : 2" *ngIf="product.end_date">
            {{ product.end_date | dateFormat }}
        </td>
        <td [attr.colspan]="1" *ngIf="!product.end_date">-</td>
        <td class="text-right" *ngIf="!canHideEndInsuranceButton">
            <a class="link-secondary link-secondary--icon-first" (click)="onCancel()" *ngIf="!product.end_date">
                <svg class="svg-icon svg-icon--s">
                    <use href="assets/icons-ui.svg#verwijder"></use>
                </svg>
                <span>Widerrufen</span>
            </a>
        </td>

        <button class="hidden btn btn--more btn--right" (click)="cancelTermination()" *ngIf="canCancelTermination">
            <svg class="svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#cross-closed"></use>
            </svg>
            <span>Doch nicht kündigen</span>
        </button>

        <ins-panel-button
            *ngIf="canTerminate"
            [target]="terminatePanel"
            openText="Kündigen"
            closeText="Schließen"
        ></ins-panel-button>
    </tr>
    <tr *ngIf="errors && errors.length">
        <td [attr.colspan]="3">
            <wuc-form-error>
                <p *ngFor="let error of errors">{{ error.message }}</p>
            </wuc-form-error>
        </td>
    </tr>
    </tbody>
</table>

<ins-panel-expandable direction="right" #terminatePanel="panel">
    <ins-terminate-insurance-container></ins-terminate-insurance-container>
</ins-panel-expandable>
<!-- TODO: WIDL-421 Cancel Add Policy -->
<table class="table-data" *ngIf="product">
    <colgroup>
        <col width="33%"/>
        <col width="33%"/>
        <col width="33%"/>
    </colgroup>
    <tbody>
    <tr>
        <td>Versicherungsnummer</td>
        <td colspan="2">{{ product.relation_id }}.{{ addLeadingZeros(product.purchased_product_sequence_number, 5) }}</td>
    </tr>
    <tr *ngIf="product.validity_status_code === validityStatusCodeEnum.Active">
        <td>Versicherungsschein</td>
        <td colspan="2">
            <a (click)="getPolicy()" href="#" target="_blank" class="link-secondary link-secondary--icon-first">
                <svg class="svg-icon svg-icon--s">
                    <use href="assets/icons-ui.svg#downloaden"></use>
                </svg>
                <span>Versicherungsschein herunterladen</span>
            </a>
        </td>
    </tr>
    <tr *ngIf="['ACTI', 'TOEK', 'ACEI', 'ACWI', 'NEVT', 'OSTP'].includes(product.validity_status_code)">
        <td>Versicherungsbedingungen</td>
        <td colspan="2">
            <a
                href="{{ documentsEnum.AllgemeineBedingungenAutoversicherung }}"
                class="link-secondary link-secondary--icon-first"
                target="_blank"
            >
                <svg class="svg-icon svg-icon--s">
                    <use href="assets/icons-ui.svg#downloaden"></use>
                </svg>
                <span>Versicherungsbedingungen Autoversicherung herunterlanden</span>
            </a>
        </td>
    </tr>
    </tbody>
</table>
