import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NewModal } from 'outshared-lib';
import { BlueConicService } from '@app-de/core/analytics/blueconic.service';
import { StorageService } from '@app-de/core/storage/universal.inject';
import { CookieSettingsWallModal } from '@app-de/modals/cookie-settings-wall-modal/cookie-settings-wall.modal';

@Component({
    selector: 'ins-cookiebar-container',
    template: '',
})
export class CookiebarContainer implements OnInit {
    constructor(
        @Inject(PLATFORM_ID) private platform: Object,
        private blueConicService: BlueConicService,
        @Inject(StorageService) private storage: Storage,
        private newModal: NewModal,
    ) {}

    public ngOnInit(): void {
        // First check if the environment is a browser
        if (isPlatformBrowser(this.platform)) {
            this.initCookiebar();
        }
    }

    private initCookiebar(): void {
        this.blueConicService.init();
        // Check if a cookie is set before opening a the cookiewall
        if (!this.storage.getItem('ls.cookie.setting')) {
            this.newModal.open<CookieSettingsWallModal>(CookieSettingsWallModal, { disableClose: true });
        }
    }
}
