import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CredentialsInterface } from '../../authentication';
import { ErrorInterface, ResponseInterface } from '../../core';
import { RecoveryMethodsResponseInterface, ValidateGeneratedAccountResponseInterface } from '../interfaces';
import { AccountRecoveryApiService } from '../services/account-recovery.api.service';
import { AccountRecoveryEventService } from '../services/account-recovery.event.service';
import { AccountRecoveryStorageService } from '../services/account-recovery.storage.service';
import {
    AccountRecoveryActions,
    CompleteGeneratedAccountAction,
    CompleteGeneratedAccountErrorAction,
    CompleteGeneratedAccountSuccessAction,
    ForgotEmailAction,
    ForgotEmailErrorAction,
    ForgotEmailSuccessAction,
    LoadCredentialsErrorAction,
    RecoveryMethodsAction,
    RecoveryMethodsErrorAction,
    RecoveryMethodsSuccessAction,
    SendPasswordAction,
    SendPasswordErrorAction,
    SendPasswordSuccessAction,
    SetCredentialsAction,
    UpdateEmailAction,
    UpdateEmailErrorAction,
    UpdateEmailSuccessAction,
    UpdatePasswordAction,
    UpdatePasswordErrorAction,
    UpdatePasswordSuccessAction,
    UpdateTemporaryPasswordAction,
    UpdateTemporaryPasswordErrorAction,
    UpdateTemporaryPasswordSuccessAction,
    ValidateGeneratedAccountAction,
    ValidateGeneratedAccountErrorAction,
    ValidateGeneratedAccountSuccessAction,
} from './account-recovery.actions';

@Injectable()
export class AccountRecoveryEffects {
    /** Recovery Methods */
    public recoveryMethods$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.RecoveryMethods),
            switchMap((action: RecoveryMethodsAction) =>
                this.apiService.getRecoveryMethods$(action.payload.request).pipe(
                    map((response: RecoveryMethodsResponseInterface) => new RecoveryMethodsSuccessAction({ response })),
                    catchError((errors: ErrorInterface[]) => of(new RecoveryMethodsErrorAction({ errors })))
                )
            )
        );
    });

    public recoveryMethodsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.RecoveryMethodsSuccess),
                tap(() => this.eventService.onRecoveryMethodsSuccess())
            );
        },
        { dispatch: false }
    );

    public recoveryMethodsError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.RecoveryMethodsError),
                map((action: RecoveryMethodsErrorAction) =>
                    this.eventService.onRecoveryMethodsError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    /** Send Password */
    public sendPassword$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.SendPassword),
            switchMap((action: SendPasswordAction) =>
                this.apiService.sendPassword$(action.payload.request).pipe(
                    map(
                        (response: ResponseInterface) =>
                            new SendPasswordSuccessAction({ notifications: response.errors })
                    ),
                    catchError((errors: ErrorInterface[]) => of(new SendPasswordErrorAction({ errors })))
                )
            )
        );
    });

    public sendPasswordSuccess$: Observable<SendPasswordSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.SendPasswordSuccess),
                tap((action: SendPasswordSuccessAction) =>
                    this.eventService.onSendPasswordSuccess(action.payload.notifications)
                )
            );
        },
        { dispatch: false }
    );

    public sendPasswordError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.SendPasswordError),
                map((action: SendPasswordErrorAction) => this.eventService.onSendPasswordError(action.payload.errors))
            );
        },
        { dispatch: false }
    );

    /** Update Temporary Password */
    public updateTemporaryPassword$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.UpdateTemporaryPassword),
            switchMap((action: UpdateTemporaryPasswordAction) => {
                return this.apiService.updateTemporaryPassword$(action.payload.request).pipe(
                    tap(() => {
                        const credentials: CredentialsInterface = {
                            email: action.payload.request.email,
                            password: action.payload.request.newPassword,
                        };
                        this.storageService.setCredentials(credentials);
                    }),
                    map(() => new UpdateTemporaryPasswordSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new UpdateTemporaryPasswordErrorAction({ errors })))
                );
            })
        );
    });

    public updateTemporaryPasswordSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.UpdateTemporaryPasswordSuccess),
                tap(() => this.eventService.onUpdateTemporaryPasswordSuccess())
            );
        },
        { dispatch: false }
    );

    public updateTemporaryPasswordError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.UpdateTemporaryPasswordError),
                map((action: UpdateTemporaryPasswordErrorAction) => {
                    return this.eventService.onUpdateTemporaryPasswordError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    /** Update Password */
    public updatePassword$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.UpdatePassword),
            switchMap((action: UpdatePasswordAction) => {
                return this.apiService.updatePassword$(action.payload.password).pipe(
                    map(() => new UpdatePasswordSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new UpdatePasswordErrorAction({ errors })))
                );
            })
        );
    });

    public updatePasswordSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.UpdatePasswordSuccess),
                tap(() => this.eventService.onUpdatePasswordSuccess())
            );
        },
        { dispatch: false }
    );

    public updatePasswordError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.UpdatePasswordError),
                map((action: UpdatePasswordErrorAction) => {
                    return this.eventService.onUpdatePasswordError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    /** Update Email */
    public updateEmail$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.UpdateEmail),
            switchMap((action: UpdateEmailAction) => {
                return this.apiService.updateEmail$(action.payload.request).pipe(
                    map(() => new UpdateEmailSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new UpdateEmailErrorAction({ errors })))
                );
            })
        );
    });

    public updateEmailSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.UpdateEmailSuccess),
                tap(() => this.eventService.onUpdateEmailSuccess())
            );
        },
        { dispatch: false }
    );

    public updateEmailError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.UpdateEmailError),
                map((action: UpdateEmailErrorAction) => {
                    return this.eventService.onUpdateEmailError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    /** Forgot Email */
    public forgotEmail$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.ForgotEmail),
            switchMap((action: ForgotEmailAction) =>
                this.apiService.forgotEmail$(action.payload.request).pipe(
                    map(
                        (response: ResponseInterface) =>
                            new ForgotEmailSuccessAction({ notifications: response.errors })
                    ),
                    catchError((errors: ErrorInterface[]) => of(new ForgotEmailErrorAction({ errors })))
                )
            )
        );
    });

    public forgotEmailSuccess$: Observable<ForgotEmailSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.ForgotEmailSuccess),
                tap((action: ForgotEmailSuccessAction) =>
                    this.eventService.onForgotEmailSuccess(action.payload.notifications)
                )
            );
        },
        { dispatch: false }
    );

    public forgotEmailError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.ForgotEmailError),
                map((action: ForgotEmailErrorAction) => this.eventService.onForgotEmailError(action.payload.errors))
            );
        },
        { dispatch: false }
    );

    /** Validate Generated Account */
    public validateGeneratedAccount$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.ValidateGeneratedAccount),
            switchMap((action: ValidateGeneratedAccountAction) => {
                return this.apiService.validateGeneratedAccount$(action.payload.request).pipe(
                    map((response: ValidateGeneratedAccountResponseInterface) => {
                        return new ValidateGeneratedAccountSuccessAction({ response });
                    }),
                    catchError((errors: ErrorInterface[]) => of(new ValidateGeneratedAccountErrorAction({ errors })))
                );
            })
        );
    });

    public validateGeneratedAccountSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.ValidateGeneratedAccountSuccess),
                tap(() => this.eventService.onValidateGeneratedAccountSuccess())
            );
        },
        { dispatch: false }
    );

    public validateGeneratedAccountError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.ValidateGeneratedAccountError),
                map((action: ValidateGeneratedAccountErrorAction) => {
                    return this.eventService.onValidateGeneratedAccountError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    /** Complete Generated Account */
    public completeGeneratedAccount$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.CompleteGeneratedAccount),
            switchMap((action: CompleteGeneratedAccountAction) => {
                return this.apiService.completeGeneratedAccount$(action.payload.request).pipe(
                    tap(() => {
                        const credentials: CredentialsInterface = {
                            email: action.payload.request.email,
                            password: action.payload.request.newPassword,
                        };
                        this.storageService.setCredentials(credentials);
                    }),
                    map(() => new CompleteGeneratedAccountSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new CompleteGeneratedAccountErrorAction({ errors })))
                );
            })
        );
    });

    public completeGeneratedAccountSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.CompleteGeneratedAccountSuccess),
                tap(() => this.eventService.onCompleteGeneratedAccountSuccess())
            );
        },
        { dispatch: false }
    );

    public completeGeneratedAccountError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.CompleteGeneratedAccountError),
                map((action: CompleteGeneratedAccountErrorAction) => {
                    return this.eventService.onCompleteGeneratedAccountError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public setCredentials$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AccountRecoveryActions.SetCredentials),
                map((action: SetCredentialsAction) => this.storageService.setCredentials(action.payload.credentials))
            );
        },
        { dispatch: false }
    );

    public loadCredentials$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AccountRecoveryActions.LoadCredentials),
            switchMap(() =>
                this.storageService.getCredentials$().pipe(
                    map((credentials: CredentialsInterface) => new SetCredentialsAction({ credentials })),
                    catchError(() => of(new LoadCredentialsErrorAction()))
                )
            )
        );
    });

    constructor(
        private action$: Actions,
        private apiService: AccountRecoveryApiService,
        private eventService: AccountRecoveryEventService,
        private storageService: AccountRecoveryStorageService
    ) {}
}
