<div class="anchor-navigation" [class.anchor-navigation--expanded]="expanded">
  <div class="anchor-navigation__label">
    <ng-content select="[slot='label']"></ng-content>
  </div>
  <ng-content select="[slot='links']"></ng-content>
  <ng-container *ngFor="let link of links">
    <wuc-link type="secondary" class="anchor-navigation__links" (clicked)="emitScrollTo(link.anchor)">
      {{ link.label }}
    </wuc-link>
  </ng-container>
  <wuc-icon-ui
    class="anchor-navigation__toggle"
    [icon]="expanded ? 'arrow-up-bold' : 'arrow-down-bold'"
    type="dark"
    size="s"
    (click)="this.expanded = !this.expanded"
  ></wuc-icon-ui>
</div>
