import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebsiteUiComponentsModule } from '@inshared/website-ui-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    ApiVersionEnum,
    AuthenticationEffects,
    CoreReducers,
    OutAccountRecoveryModule,
    OutAuthenticationModule,
    OutContentModule,
    OutCoreModule,
    OutRelationModule,
    OutValidationModule,
    ANALYTICS_CONFIGURATION,
    AnalyticsConfigurationInterface,
    LabelEnum,
    provideDatalayer,
} from 'outshared-lib';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppConfigService, initializeAppFactory } from './core/configuration';
import { CoreModule } from './core/core.module';
import { QuestionModalModule } from './modals/question-modal/question.modal.module';
import { BaseLayoutModule } from './shared/layout/base-layout/base-layout.module';
import { AccountRecoveryModule } from '@app-de/account-recovery';
import { LivechatModule } from '@app-de/core/livechat/livechat.module';
import { ComplaintModalModule } from '@app-de/modals/complaint-modal/complaint.modal.module';
import { RoutingModule } from '@app-de/routing';
import { ChatbotFixedModule, ChatbotModalModule } from '@app-de/shared/customer-service/chatbot';
import { reducers } from '@app-de/store/app.reducer';
import { windowProvider } from '@inshared/shared/util';

@NgModule({
    declarations: [AppComponent],
    providers: [
        AppConfigService,
        windowProvider,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AppConfigService],
            multi: true,
        },
        {
            provide: ANALYTICS_CONFIGURATION,
            useFactory: (appConfigService: AppConfigService): AnalyticsConfigurationInterface => {
                return {
                    cynolyticsUrl: appConfigService.getConfig()?.cynolyticsUrl,
                    cynolyticsPartnerId: appConfigService.getConfig()?.cynolyticsPartnerId,
                    cynolyticsLanguageId: appConfigService.getConfig()?.cynolyticsLanguageId,
                    gtmId: appConfigService.getConfig()?.gtmId,
                };
            },
            deps: [AppConfigService],
        },
        provideDatalayer(),
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'insh-de-app' }),
        BrowserAnimationsModule,

        // NGRX Store
        StoreModule.forRoot(reducers, { metaReducers: CoreReducers.metaReducers }),
        EffectsModule.forRoot([AuthenticationEffects]),
        environment.production ? [] : StoreDevtoolsModule.instrument(),

        // Application
        AccountRecoveryModule,
        BaseLayoutModule,
        ChatbotFixedModule,
        ChatbotModalModule,
        CoreModule,
        RoutingModule,
        QuestionModalModule,
        ComplaintModalModule,
        // OutShared Library
        OutCoreModule.forRoot(
            {
                production: environment.production,
                debug: false,
                apiUrl: '/api/v2',
                version: ApiVersionEnum.International,
                label: LabelEnum.ISDE,
            },
            { indexableDomains: ['inshared.de', 'www.inshared.de'], notIndexableDomains: ['demo.inshared.de'] },
        ),
        OutRelationModule,
        LivechatModule.forRoot({
            scriptSrc: 'https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js',
            dataAppId: 'c71e5983-d5ec-48b9-b77a-070e66c31d6f',
            dataLcwVersion: 'prod',
            dataOrgId: 'b4afcb63-3171-4df7-b319-adb9d895aec4',
            dataOrgUrl: 'https://unqb4afcb6331714df7b319adb9d895a-crm4.omnichannelengagementhub.com',
        }),
        OutAuthenticationModule,
        OutAccountRecoveryModule,
        OutContentModule,
        OutValidationModule,

        // Website UI components
        WebsiteUiComponentsModule.forRoot({
            pikadayConfig: {
                format: 'DD.MM.YYYY',
                placeholder: 'TT.MM.JJJJ',
                i18n: {
                    previousMonth: 'Letzten Monat zeigen',
                    nextMonth: 'Nächsten Monat zeigen',
                    months: [
                        'Januar',
                        'Februar',
                        'März',
                        'April',
                        'Mai',
                        'Juni',
                        'Juli',
                        'August',
                        'September',
                        'Oktober',
                        'November',
                        'Dezember',
                    ],
                    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                    weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                },
            },
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
