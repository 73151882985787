import { Injectable } from '@angular/core';
import { SalesfunnelRoutes } from './routing/salesfunnel-routing.routes';

@Injectable({ providedIn: 'root' })
export class SalesfunnelService {
    public isSalesfunnel(url: string): boolean {
        const fragments = url.split('/').filter((item: string) => item);
        return fragments.length > 0 && fragments[1] === `${fragments[0]}-${ SalesfunnelRoutes.Calculate }`;
    }
}
