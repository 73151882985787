<ngx-loading-bar color="#f173ac" [includeSpinner]="false"></ngx-loading-bar>
<section class="cookiebar">
    <ins-cookiebar-container></ins-cookiebar-container>
</section>

<ins-header></ins-header>

<main role="main">
    <ins-breadcrumb-container *ngIf="!((seoService.routeData$ | async)?.['hideBreadcrumb'])"></ins-breadcrumb-container>
    <router-outlet></router-outlet>
</main>

<section class="section section--white section--bordered">
    <div class="container">
        <div class="wrapper">
            <div class="col-8">
                <ins-breadcrumb-bordered-container></ins-breadcrumb-bordered-container>
            </div>
            <div class="col-4">
                <ins-slogan></ins-slogan>
            </div>
        </div>
    </div>
</section>

<ins-footer></ins-footer>

<ins-chatbot-fixed-container></ins-chatbot-fixed-container>
<router-outlet name="modal"></router-outlet>
