import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { RequestService } from 'outshared-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressInterface } from './address.interface';
import { AppConfigService } from '@app-de/core/configuration';
import {
    AddressFindResponseInterface,
    AddressFindResponseOptionsInterface,
} from '@app-de/shared/address-autocomplete/address-retrieve-option.interface';

@Injectable({
    providedIn: 'root',
})
export class AddressAutocompleteService {
    public readonly addressOptionsValueSubject = new BehaviorSubject(null);
    public readonly addressOptions$ = this.addressOptionsValueSubject.asObservable();

    private get loqateKey(): string {
        return this.appConfigService.getConfig().loqateKey;
    }

    constructor(
        private http: HttpClient,
        private requestService: RequestService,
        private appConfigService: AppConfigService,
    ) {}

    public setAddressOptions(options: any): void {
        this.addressOptionsValueSubject.next(options);
    }

    public performRetrieveSearch$(configObject: {
        addressOption: AddressFindResponseOptionsInterface;
        optionsRef: ElementRef;
        inputRef: ElementRef;
    }): Observable<AddressInterface> {
        return this.http
            .get('https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws', {
                params: {
                    Key: this.loqateKey,
                    id: configObject.addressOption.Id,
                },
            })
            .pipe(
                map((response: { Items: any }) => {
                    if (!response.Items[0]) {
                        return null;
                    }

                    const address = response.Items[0];

                    const addressObject: AddressInterface = {
                        address_id: address.Id,
                        street_name: address.Street,
                        civic_number: address.BuildingNumber,
                        postal_code: address.PostalCode,
                        city: address.City,
                        province_name: address.ProvinceName,
                        country: address.CountryName,
                    };

                    return addressObject;
                }),
            );
    }

    public performFindSearch$(configObject): Observable<AddressFindResponseInterface> {
        return this.http.get<AddressFindResponseInterface>(
            'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws',
            {
                params: {
                    Key: this.loqateKey,
                    Container: configObject.addressOption.Id,
                },
            },
        );
    }

    public performFindAddressSearch$(configObject, userPostcode): Observable<AddressFindResponseInterface> {
        return this.http.get<AddressFindResponseInterface>(
            'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws',
            {
                params: {
                    Key: this.loqateKey,
                    Text: configObject.searchQuery,
                    Countries: 'DE',
                    Filter: userPostcode,
                },
            },
        );
    }

    public requestResidentialAdressDetail$(addressId: string): Observable<AddressInterface> {
        const payload = {
            address_id: addressId,
        };
        return this.requestService.post('en-gb/address-general/request/residential-address-detail', payload).pipe(
            map((response) => response.data),
            map(({ address }) => ({
                address_id: address.international_address_id,
                street_name: address.street_name,
                civic_number: address.civic_number,
                postal_code: address.postal_code,
                city: address.city,
                province_name: address.province_description,
                country: address.country_description,
            })),
        );
    }
}
