import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ContentElementRequestInterface, ContentInterface } from '../interfaces';
import { mapContentElementsResponse } from '../mappers';

const RESOURCES = {
    REQUEST_CONTENT_ELEMENTS: 'en-gb/form-general/request/element',
};

@Injectable({
    providedIn: 'root',
})
export class ContentApiService {
    constructor(private apiService: ApiService) {}

    public requestContentElements$(element: ContentElementRequestInterface): Observable<ContentInterface[]> {
        return this.apiService
            .post$(RESOURCES.REQUEST_CONTENT_ELEMENTS, { elements: [element]})
            .pipe(map(mapContentElementsResponse));
    }
}
