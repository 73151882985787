import { PersonalInformationInterface, RecoveryMethodsResponseInterface } from '../interfaces';

export interface AccountRecovery {
    accountRecovery: AccountRecoveryState;
}

export interface AccountRecoveryState {
    recoveryMethods: RecoveryMethodsResponseInterface;
    personalInformation: PersonalInformationInterface;
}

export const INITIAL_ACCOUNT_RECOVERY_STATE: AccountRecoveryState = {
    recoveryMethods: null,
    personalInformation: null,
};

export const ACCOUNT_RECOVERY_FEATURE = 'accountRecovery';
