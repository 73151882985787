import { Component, Input } from '@angular/core';
import { SmartTipsImageEnum } from '../../smart-tips-image.enum';
import { SmartTipsEnum } from '../../smart-tips.enum';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-auto-reisezeit-summary',
    templateUrl: './auto-reisezeit-summary.component.html',
})
export class AutoReisezeitSummaryComponent {
    @Input() public smartTipsCarousel: boolean = false;

    public smartTipsEnum = SmartTipsEnum;
    public appRoutesEnum = AppRoutesEnum;
    public smartTipsImageEnum = SmartTipsImageEnum;
}
