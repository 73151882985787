export enum AutoversicherungRoutesEnum {
    Autoversicherung = '',
    EAutoversicherung = 'e-auto-versicherung',
    KfzHaftpflichtversicherung = 'kfz-haftpflichtversicherung',
    AutoversicherungVergleichen = 'autoversicherung-vergleichen',
    Teilkasko = 'teilkasko',
    Vollkasko = 'vollkasko',
    Fahrerschutz = 'fahrerschutz',
    Pannenhilfe = 'pannenhilfe',
    Reviews = 'bewertungen',
    CarBrands = 'automarken',
    RatgeberZulassungsstelle = 'ratgeber-zulassungsstelle',
}

export enum AutoversicherungCitiesRoutesEnum {
    Koeln = 'koeln',
    Bonn = 'bonn',
    Berlin = 'berlin',
    Muenchen = 'muenchen',
    Hamburg = 'hamburg',
}
