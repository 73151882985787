import { ApiUpdateTemporaryPasswordRequest, UpdateTemporaryPasswordRequestInterface } from '../interfaces';

export function mapUpdateTemporaryPasswordRequest(
    request: UpdateTemporaryPasswordRequestInterface
): ApiUpdateTemporaryPasswordRequest {
    return {
        current_password: request.temporaryPassword,
        new_password: request.newPassword,
    };
}
