<button
  class="wuc-button"
  [type]="isSubmitButton ? 'submit' : 'button'"
  [class.wuc-button--primary]="type === 'primary'"
  [class.wuc-button--secondary]="type === 'secondary'"
  [class.wuc-button--clean]="type === 'clean'"
  [class.wuc-button--small]="small"
  [class.wuc-button--icon]="icon"
  [class.wuc-button--icon-right]="iconRight"
  [class.wuc-button--icon-left]="iconLeft"
  [class.wuc-button--full-width]="isFullWidth"
  [class.wuc-button--has-content]="!!contentRef.innerText"
  [disabled]="disabled"
  [title]="title"
  [tabindex]="tabindex"
  (click)="onClick($event)"
>
  <span class="wuc-button__icon" *ngIf="icon">
    <wuc-icon-ui [icon]="icon" [type]="iconType" [size]="small ? 'xs' : 's'"></wuc-icon-ui>
  </span>
  <span class="wuc-button__icon-left" *ngIf="iconLeft">
    <wuc-icon-ui [icon]="iconLeft" [type]="iconType" [size]="small ? 'xs' : 's'"></wuc-icon-ui>
  </span>
  <span #contentRef>
    <ng-content></ng-content>
  </span>
  <span class="wuc-button__icon-right" *ngIf="iconRight">
    <wuc-icon-ui [icon]="iconRight" [type]="iconType" size="xs"></wuc-icon-ui>
  </span>
</button>
