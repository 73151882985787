export enum KnowledgebaseRoutesEnum {
    Schadenfreiheitsrabatt = 'schadenfreiheitsrabatt',
    Schadenfreiheitsklasse = 'schadenfreiheitsklasse',
    EvbNummer = 'evb-nummer',
    KfzVersicherung = 'kfz-versicherung',
    KfzHaftpflichtversicherung = 'kfz-haftpflichtversicherung',
    Teilkaskoversicherung = 'teilkaskoversicherung',
    Vollkaskoversicherung = 'vollkaskoversicherung',
    RatgeberKfzVersichern = 'ratgeber-kfz-versichern',
    VollkaskoversicherungTipps= 'vollkaskoversicherung-tipps',
    TeilkaskoversicherungTipps = 'teilkaskoversicherung-tipps',
    KfzHaftpflichtVersicherungTipps= 'kfz-haftpflichtversicherung-tipps'
}
