import { Action } from '@ngrx/store';
import { CredentialsInterface } from '../../authentication';
import { ErrorInterface } from '../../core';
import {
    CompleteGeneratedAccountRequestInterface,
    ForgotEmailRequestInterface,
    RecoveryMethodsRequestInterface,
    RecoveryMethodsResponseInterface,
    SendPasswordRequestInterface,
    UpdateTemporaryPasswordInterface,
    ValidateGeneratedAccountRequestInterface,
    ValidateGeneratedAccountResponseInterface,
    UpdateEmailAddressRequestInterface,
} from '../interfaces';

export enum AccountRecoveryActions {
    RecoveryMethods = '[Account Recovery] Recovery Methods',
    RecoveryMethodsSuccess = '[Account Recovery] Recovery Methods Success',
    RecoveryMethodsError = '[Account Recovery] Recovery Methods Error',

    SendPassword = '[Account Recovery] Send Password',
    SendPasswordSuccess = '[Account Recovery] Send Password Success',
    SendPasswordError = '[Account Recovery] Send Password Error',

    UpdateTemporaryPassword = '[Account Recovery] Update Temporary Password',
    UpdateTemporaryPasswordSuccess = '[Account Recovery] Update Temporary Password Success',
    UpdateTemporaryPasswordError = '[Account Recovery] Update Temporary Password Error',

    UpdatePassword = '[Account Recovery] Update Password',
    UpdatePasswordSuccess = '[Account Recovery] Update Password Success',
    UpdatePasswordError = '[Account Recovery] Update Password Error',

    UpdateEmail = '[Account Recovery] Update Email',
    UpdateEmailSuccess = '[Account Recovery] Update Email Success',
    UpdateEmailError = '[Account Recovery] Update Email Error',

    ForgotEmail = '[Account Recovery] Forgot Email',
    ForgotEmailSuccess = '[Account Recovery] Forgot Email Success',
    ForgotEmailError = '[Account Recovery] Forgot Email Error',

    ValidateGeneratedAccount = '[Account Recovery] Validate Generated Account',
    ValidateGeneratedAccountSuccess = '[Account Recovery] Validate Generated Account Success',
    ValidateGeneratedAccountError = '[Account Recovery] Validate Generated Account Error',

    CompleteGeneratedAccount = '[Account Recovery] Complete Generated Account',
    CompleteGeneratedAccountSuccess = '[Account Recovery] Complete Generated Account Success',
    CompleteGeneratedAccountError = '[Account Recovery] Complete Generated Account Error',

    SetCredentials = '[Account Recovery] Set Credentials',
    LoadCredentials = '[Account Recovery] Load Credentials',
    LoadCredentialsError = '[Account Recovery] Load Credentials Success',

    AccountRecoveryReset = '[Account Recovery] Reset',
}

/** Recovery Methods */
export class RecoveryMethodsAction implements Action {
    public readonly type = AccountRecoveryActions.RecoveryMethods;

    constructor(public payload: { request: RecoveryMethodsRequestInterface }) {}
}

export class RecoveryMethodsSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.RecoveryMethodsSuccess;

    constructor(public payload: { response: RecoveryMethodsResponseInterface }) {}
}

export class RecoveryMethodsErrorAction implements Action {
    public readonly type = AccountRecoveryActions.RecoveryMethodsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Send Password */
export class SendPasswordAction implements Action {
    public readonly type = AccountRecoveryActions.SendPassword;

    constructor(public payload: { request: SendPasswordRequestInterface }) {}
}

export class SendPasswordSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.SendPasswordSuccess;

    constructor(public payload: { notifications: ErrorInterface[] }) {}
}

export class SendPasswordErrorAction implements Action {
    public readonly type = AccountRecoveryActions.SendPasswordError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Update Temporary Password */
export class UpdateTemporaryPasswordAction implements Action {
    public readonly type = AccountRecoveryActions.UpdateTemporaryPassword;

    constructor(public payload: { request: UpdateTemporaryPasswordInterface }) {}
}

export class UpdateTemporaryPasswordSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.UpdateTemporaryPasswordSuccess;
}

export class UpdateTemporaryPasswordErrorAction implements Action {
    public readonly type = AccountRecoveryActions.UpdateTemporaryPasswordError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Update Password */
export class UpdatePasswordAction implements Action {
    public readonly type = AccountRecoveryActions.UpdatePassword;

    constructor(public payload: { password: string }) {}
}

export class UpdatePasswordSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.UpdatePasswordSuccess;
}

export class UpdatePasswordErrorAction implements Action {
    public readonly type = AccountRecoveryActions.UpdatePasswordError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Update Email */
export class UpdateEmailAction implements Action {
    public readonly type = AccountRecoveryActions.UpdateEmail;

    constructor(public payload: { request: UpdateEmailAddressRequestInterface }) {}
}

export class UpdateEmailSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.UpdateEmailSuccess;
}

export class UpdateEmailErrorAction implements Action {
    public readonly type = AccountRecoveryActions.UpdateEmailError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Forgot Email */
export class ForgotEmailAction implements Action {
    public readonly type = AccountRecoveryActions.ForgotEmail;

    constructor(public payload: { request: ForgotEmailRequestInterface }) {}
}

export class ForgotEmailSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.ForgotEmailSuccess;

    constructor(public payload: { notifications: ErrorInterface[] }) {}
}

export class ForgotEmailErrorAction implements Action {
    public readonly type = AccountRecoveryActions.ForgotEmailError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Validate Generated Account */
export class ValidateGeneratedAccountAction implements Action {
    public readonly type = AccountRecoveryActions.ValidateGeneratedAccount;

    constructor(public payload: { request: ValidateGeneratedAccountRequestInterface }) {}
}

export class ValidateGeneratedAccountSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.ValidateGeneratedAccountSuccess;

    constructor(public payload: { response: ValidateGeneratedAccountResponseInterface }) {}
}

export class ValidateGeneratedAccountErrorAction implements Action {
    public readonly type = AccountRecoveryActions.ValidateGeneratedAccountError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Complete Generated Account */
export class CompleteGeneratedAccountAction implements Action {
    public readonly type = AccountRecoveryActions.CompleteGeneratedAccount;

    constructor(public payload: { request: CompleteGeneratedAccountRequestInterface }) {}
}

export class CompleteGeneratedAccountSuccessAction implements Action {
    public readonly type = AccountRecoveryActions.CompleteGeneratedAccountSuccess;
}

export class CompleteGeneratedAccountErrorAction implements Action {
    public readonly type = AccountRecoveryActions.CompleteGeneratedAccountError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Credentials */
export class SetCredentialsAction implements Action {
    public readonly type = AccountRecoveryActions.SetCredentials;

    constructor(public payload: { credentials: CredentialsInterface }) {}
}

export class LoadCredentialsAction implements Action {
    public readonly type = AccountRecoveryActions.LoadCredentials;
}

export class LoadCredentialsErrorAction implements Action {
    public readonly type = AccountRecoveryActions.LoadCredentialsError;
}

/** Reset */
export class AccountRecoveryResetAction implements Action {
    public readonly type = AccountRecoveryActions.AccountRecoveryReset;
}

export type AccountRecoveryActionType =
    | RecoveryMethodsAction
    | RecoveryMethodsSuccessAction
    | RecoveryMethodsErrorAction
    | SendPasswordAction
    | SendPasswordSuccessAction
    | SendPasswordErrorAction
    | UpdateTemporaryPasswordAction
    | UpdateTemporaryPasswordSuccessAction
    | UpdateTemporaryPasswordErrorAction
    | UpdatePasswordAction
    | UpdatePasswordSuccessAction
    | UpdatePasswordErrorAction
    | UpdateEmailAction
    | UpdateEmailSuccessAction
    | UpdateEmailErrorAction
    | ForgotEmailAction
    | ForgotEmailSuccessAction
    | ForgotEmailErrorAction
    | ValidateGeneratedAccountAction
    | ValidateGeneratedAccountSuccessAction
    | ValidateGeneratedAccountErrorAction
    | CompleteGeneratedAccountAction
    | CompleteGeneratedAccountSuccessAction
    | CompleteGeneratedAccountErrorAction
    | SetCredentialsAction
    | LoadCredentialsAction
    | LoadCredentialsErrorAction
    | AccountRecoveryResetAction;
