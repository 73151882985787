export enum AppRoutesEnum {
    Home = '',
    /* Login */
    Login = 'inloggen',
    Logout = 'uitloggen',
    HemaActivation = 'activeren',
    /* My Zone */
    My = 'mijn',
    MyZoneOld = 'mijn-verzekeringsmap',
    MyZone = 'verzekeringsmap',
    MyInsurances = 'mijn-verzekeringen',
    MyData = 'mijn-gegevens',
    MyClaims = 'mijn-schades',
    MyClaimsClaim = 'schadeclaim',
    MyLoginData = 'inloggegevens',
    MyAnnualReward = 'jaarbeloning',
    /* Funnel */
    Calculate = 'berekenen',
    Activate = 'activeren',
    Thanks = 'bedankpagina',
    /* Insurances */
    Insurances = 'verzekeringen',
    CancelInsurance = 'opzeggen',
    CarInsurance = 'autoverzekering',
    BicycleInsurance = 'fietsverzekering',
    MopedInsurance = 'brommerverzekering',
    ScooterInsurance = 'scooterverzekering',
    MotorInsurance = 'motorverzekering',
    TravelInsurance = 'reisverzekering',
    HomeInsurance = 'woonverzekering',
    InventoryInsurance = 'inboedelverzekering',
    BuildingInsurance = 'opstalverzekering',
    LegalInsurance = 'rechtsbijstandverzekering',
    CaravanInsurance = 'caravanverzekering',
    AccidentInsurance = 'ongevallenverzekering',
    LiabilityInsurance = 'aansprakelijkheidsverzekering',
    PetInsurance = 'dierenverzekering',
    DogInsurance = 'hondenverzekering',
    CatInsurance = 'kattenverzekering',
    /* Damage */
    Damage = 'schade',
    DamageRepair = 'schadeherstel',
    DamageReport = 'schadeverslag',
    DamageReportingHowitWorks = 'hoe-werkt-online-schademelden',
    /* Other pages */
    AnnualReward = 'jaarbeloning',
    AnnualReward2020 = 'jaarbeloning-2020',
    AnnualReward2021 = 'jaarbeloning-2021',
    AnnualReward2022 = 'jaarbeloning-2022',
    AnnualRewardMostRecent = 'jaarbeloning-2023',
    Avg = 'avg',
    Collectivity = 'collectiviteit',
    Contact = 'contact',
    Reviews = 'reviews',
    Cookies = 'cookies',
    About = 'over-inshared',
    EverythingOnline = 'alles-online',
    PayPremium = 'premie-betalen',
    Vacancies = 'werken-bij',
    Privacy = 'privacy',
    OptOut = 'optout',
    CustomerSatisfaction = 'hoe-tevreden-zijn-klanten',
    SmartTips = 'slimme-tips',
    SwitchService = 'overstaphulp',
    Prevention = 'preventie',
    Terminate = 'opzeggen',
    // Claim funnel
    ClaimFunnel = 'verzekeringsmap/mijn-schades/schademelden',
    OldClaimFunnel = '/mijn-verzekeringsmap/schaderegelen#/schademelden',
}
