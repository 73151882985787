<ins-smart-tips-card [smartTipsCarousel]="smartTipsCarousel">
    <a class="card__link" [routerLink]="['/', appRoutesEnum.SmartTips, smartTipsEnum.MitSicherheitAutopannenVermeiden]">
        <ins-card-image>
            <ins-banner
                [image]="smartTipsImageEnum.AutopanneVermeiden"
                imageAlt="Rauch steigt aus dem Automotor auf und eine Person versucht die Panne zu beheben."
                imageTitle="Autopannen müssen nicht sein. Wir zeigen, wie Sie problemlos durch den Verkehr kommen!"
                size="small"
            ></ins-banner>
        </ins-card-image>
        <ins-card-head>
            <h3>5 Tipps: So vermeiden Sie eine Autopanne</h3>
        </ins-card-head>
        <ins-card-content>
            <p class="card__content-text">
                Es gehört zu den Horrorszenarien eines jeden: eine Panne auf einer vielbefahrenen Autobahn oder in der
                Innenstadt während des Berufsverkehrs. Das muss nicht sein. Wir verraten Ihnen 5 Tipps, wie Sie zum
                Vermeiden von Autopannen und zu mehr Sicherheit auf den Straßen beitragen können.
            </p>
        </ins-card-content>
        <ins-card-footer>
            <ins-read-article [smartTip]="smartTipsEnum.MitSicherheitAutopannenVermeiden"></ins-read-article>
        </ins-card-footer>
    </a>
</ins-smart-tips-card>
