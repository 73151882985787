import { Component, Input } from '@angular/core';
import { RelationInterface } from 'outshared-lib';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-my-zone',
    templateUrl: './my-zone.component.html',
    styleUrls: ['./my-zone.component.scss'],
})
export class MyZoneComponent {
    @Input() public relation: RelationInterface;
    public appRoutesEnum = AppRoutesEnum;
}
