import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ins-card-rack',
    templateUrl: 'card-rack.component.html',
    styleUrls: ['card-rack.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardRackComponent {
    @Input() public type?: 3 | 4 | 'fullWidth';
}
