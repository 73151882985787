import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoversicherungCitiesRoutesEnum, AutoversicherungRoutesEnum } from './autoversicherung-routes.enum';
import { knowledgebaseAutoversicherungRoutes } from '@app-de/pages/car-insurance/knowledgebase/knowledgebase-routes';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: AutoversicherungRoutesEnum.Autoversicherung,
                pathMatch: 'full',
                loadChildren: () =>
                    import('./autoversicherung-product/autoversicherung-product.module').then(
                        (m) => m.AutoversicherungProductModule,
                    ),
            },
            {
                path: AutoversicherungRoutesEnum.KfzHaftpflichtversicherung,
                pathMatch: 'full',
                loadChildren: () =>
                    import('./kfz-haftpflichtversicherung/kfz-haftpflichtversicherung.module').then(
                        (m) => m.KfzHaftpflichtversicherungModule,
                    ),
            },
            {
                path: AutoversicherungRoutesEnum.EAutoversicherung,
                loadChildren: () =>
                    import('./e-autoversicherung-product/e-autoversicherung-product.module').then(
                        (m) => m.EAutoversicherungProductModule,
                    ),
            },
            {
                path: AutoversicherungRoutesEnum.AutoversicherungVergleichen,
                pathMatch: 'full',
                loadChildren: () =>
                    import('./autoversicherung-vergleichen/autoversicherung-vergleichen.module').then(
                        (m) => m.AutoversicherungVergleichenModule,
                    ),
            },
            {
                path: AutoversicherungRoutesEnum.Teilkasko,
                pathMatch: 'full',
                loadChildren: () => import('./teilkasko/teilkasko.module').then((m) => m.TeilkaskoModule),
            },
            {
                path: AutoversicherungRoutesEnum.Vollkasko,
                pathMatch: 'full',
                loadChildren: () => import('./vollkasko/vollkasko.module').then((m) => m.VollkaskoModule),
            },
            {
                path: AutoversicherungRoutesEnum.Fahrerschutz,
                pathMatch: 'full',
                loadChildren: () => import('./fahrerschutz/fahrerschutz.module').then((m) => m.FahrerschutzModule),
            },
            {
                path: AutoversicherungRoutesEnum.Pannenhilfe,
                pathMatch: 'full',
                loadChildren: () => import('./pannenhilfe/pannenhilfe.module').then((m) => m.PannenhilfeModule),
            },
            {
                path: AutoversicherungRoutesEnum.Reviews,
                pathMatch: 'full',
                loadChildren: () =>
                    import('./car-insurance-reviews/car-insurance-reviews.module').then(
                        (m) => m.CarInsuranceReviewsModule,
                    ),
            },
            {
                path: AutoversicherungRoutesEnum.CarBrands,
                loadChildren: () => import('./car-brands/car-brands.module').then((m) => m.CarBrandsModule),
            },
            {
                path: AutoversicherungCitiesRoutesEnum.Koeln,
                loadChildren: () =>
                    import('./cities/autoversicherung-koeln/autoversicherung-koeln.module').then(
                        (m) => m.AutoversicherungKoelnModule,
                    ),
            },
            {
                path: AutoversicherungCitiesRoutesEnum.Bonn,
                loadChildren: () =>
                    import('./cities/autoversicherung-bonn/autoversicherung-bonn.module').then(
                        (m) => m.AutoversicherungBonnModule,
                    ),
            },
            {
                path: AutoversicherungCitiesRoutesEnum.Berlin,
                loadChildren: () =>
                    import('./cities/autoversicherung-berlin/autoversicherung-berlin.module').then(
                        (m) => m.AutoversicherungBerlinModule,
                    ),
            },
            {
                path: AutoversicherungCitiesRoutesEnum.Muenchen,
                loadChildren: () =>
                    import('./cities/autoversicherung-muenchen/autoversicherung-muenchen.module').then(
                        (m) => m.AutoversicherungMuenchenModule,
                    ),
            },
            {
                path: AutoversicherungCitiesRoutesEnum.Hamburg,
                loadChildren: () =>
                    import('./cities/autoversicherung-hamburg/autoversicherung-hamburg.module').then(
                        (m) => m.AutoversicherungHamburgModule,
                    ),
            },
            {
                path: AutoversicherungRoutesEnum.RatgeberZulassungsstelle,
                loadChildren: () =>
                    import('./ratgeber-zulassungsstelle/ratgeber-zulassungsstelle.module').then(
                        (m) => m.RatgeberZulassungsstelleModule,
                    ),
            },
            ...knowledgebaseAutoversicherungRoutes,
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AutoversicherungRoutingModule {}
