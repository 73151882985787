import { Routes } from '@angular/router';
import { AutoversicherungRoutesEnum } from './../autoversicherung/autoversicherung-routes.enum';
import { KnowledgebaseRoutesEnum } from './knowledgebase-routes.enum';

export const knowledgebaseAutoversicherungRoutes: Routes = [
    {
        path: KnowledgebaseRoutesEnum.Schadenfreiheitsrabatt,
        pathMatch: 'full',
        loadChildren: () => import('./bonus-malus/bonus-malus.module').then((m) => m.BonusMalusModule),
    },
    {
        path: KnowledgebaseRoutesEnum.Schadenfreiheitsklasse,
        pathMatch: 'full',
        loadChildren: () => import('./schadenfreiheitsklasse/schadenfreiheitsklasse.module').then(
            (m) => m.SchadenfreiheitsklasseModule
        ),
    },
    {
        path: `${AutoversicherungRoutesEnum.RatgeberZulassungsstelle}/${KnowledgebaseRoutesEnum.EvbNummer}`,
        pathMatch: 'full',
        loadChildren: () => import('./evb-nummer/evb-nummer.module').then((m) => m.EvbNummerModule),
    },
];

export const knowledgebaseKfzVersicherungRoutes: Routes = [
    {
        path: `${KnowledgebaseRoutesEnum.RatgeberKfzVersichern}/${KnowledgebaseRoutesEnum.KfzHaftpflichtVersicherungTipps}`,
        pathMatch: 'full',
        loadChildren: () => import('./kfz-haftpflichtversicherung/kfz-haftpflichtversicherung.module').then((m) =>
            m.KfzHaftpflichtversicherungModule),
    },
    {
        path: `${KnowledgebaseRoutesEnum.RatgeberKfzVersichern}/${KnowledgebaseRoutesEnum.TeilkaskoversicherungTipps}`,
        pathMatch: 'full',
        loadChildren: () => import('./teilkaskoversicherung/teilkaskoversicherung.module').then((m) =>
            m.TeilkaskoversicherungModule),
    },
    {
        path: `${KnowledgebaseRoutesEnum.RatgeberKfzVersichern}/${KnowledgebaseRoutesEnum.VollkaskoversicherungTipps}`,
        pathMatch: 'full',
        loadChildren: () => import('./vollkaskoversicherung/vollkaskoversicherung.module').then((m) =>
            m.VollkaskoversicherungModule),
    },
];
