import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CookieConsent, GoogleConsent, LabelEnum } from '../../../core';
import { ApiDatalayerInterface } from '../../interfaces';
import { DatalayerConfigInterface } from './datalayer-config.interface';

/**
 * @deprecated
 * import: DatalayerService from outshared-lib.
 */
@Injectable({
    providedIn: 'root',
})
export class DatalayerApiService {
    private isLoaded: boolean = false;
    private initializing: boolean = false;
    private queue: ApiDatalayerInterface[] = [];

    private renderer: Renderer2;
    private defaultConsent: GoogleConsent = {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
    };

    private cookieConsent = null;

    private configuration = {
        gtmId: ''
    };

    private apiConfiguration = {
        label: LabelEnum.ISNL
    };

    private shouldInitialize(): boolean {
        const isClientSide = typeof window !== 'undefined';
        const hasGtmId = Boolean(this.configuration.gtmId);
        return isClientSide && hasGtmId;
    }

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    public setCookieConsent(cookieConsent: CookieConsent) {
        this.cookieConsent = cookieConsent;
        return this.mapConsent(cookieConsent);
    }

    /**
     * Returns window dataLayer
     */
    public getDatalayer(): ApiDatalayerInterface[] {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer;
    }

    /**
     * Initialises the service, if there is no gtmId provided return otherwise add tagManager
     * @public
     */
    public init(props?: DatalayerConfigInterface): void {
        if (!props) {
            console.error('Datalayer properties not set');
            return;
        }

        if (props?.gtmId) {
            this.configuration.gtmId = props?.gtmId;
        } else {
            console.error('Datalayer gtmId not set');
        }

        if (props?.label) {
            this.apiConfiguration.label = props?.label;
        } else {
            console.error('Datalayer label not set');
        }
        if (this.shouldInitialize()) {
            this.addTagManagerScript();
        }
    }

    /**
     * Send data to the dataLayer, if the dataLayer is not yet available push the data onto a queue for later processing
     * otherwise push directly to the dataLayer
     * @param data
     */
    public send(data: ApiDatalayerInterface): void {
        if (this.shouldInitialize()) {
            !this.isLoaded ? this.pushTag(data) : this.sendToDatalayer(data);
        }
    }

    /**
     * Adds the googleTag manager script to the DOM if it does not exist yet
     */
    public addGtmToDom$(): Observable<boolean> {
        return new Observable((observer: Subscriber<boolean>) => {
            if (this.isLoaded) {
                observer.next(true);
                return observer.complete();
            }

            const gtmScript = this.document.createElement('script');
            gtmScript.id = 'GTMscript';
            gtmScript.async = true;
            gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${this.configuration.gtmId}`;

            gtmScript.addEventListener('load', () => {
                observer.next(true);
                this.isLoaded = true;
                this.initializing = false;
            });

            gtmScript.addEventListener('error', () => {
                // Disabled because observer.error throws random errors in unit test environment
                // observer.error('failed to load google tag manager');
                return observer.complete();
            });

            this.document.head.insertBefore(gtmScript, this.document.head.firstChild);
        });
    }

    /**
     * If google tag manager is loaded and the dataLayer initialized pushes data onto the dataLayer, otherwise it
     *
     * @param item
     */
    public pushTag(item: ApiDatalayerInterface): Subscription | void {
        if (this.shouldInitialize()) {
            if (!this.isLoaded && this.initializing) {
                this.queue.push(item);
            }
            if (!this.isLoaded && !this.initializing) {
                this.initializing = true;
                return this.addGtmToDom$()
                    .pipe(take(1))
                    .subscribe(() => this.sendToDatalayer(item));
            }
            if (this.isLoaded && !this.initializing && this.queue.length > 0) {
                this.queue.forEach((queueItem) => this.sendToDatalayer(queueItem));
                this.queue = [];
            }
            this.sendToDatalayer(item);
        }
    }

    private mapConsent(consent: CookieConsent): GoogleConsent {
        switch (consent) {
            case CookieConsent.All:
                return {
                    ...this.defaultConsent,
                    ad_storage: 'granted',
                    ad_user_data: 'granted',
                    ad_personalization: 'granted',
                    analytics_storage: 'granted',
                };
            case CookieConsent.Extensive: {
                return {
                    ...this.defaultConsent,
                    analytics_storage: 'granted',
                };
            }
            case CookieConsent.Basic:
            case 'basis' as CookieConsent: // Temp for DE until one year from now or until cookies invalidated
                if (this.apiConfiguration.label !== LabelEnum.ISDE) {
                    return {
                        ...this.defaultConsent,
                        analytics_storage: 'granted',
                    };
                }
                return this.defaultConsent;
            default:
                return this.defaultConsent;
        }
    }

    private updateDatalayerConsent(action: 'default' | 'update', consent: GoogleConsent) {
        if (typeof window !== 'undefined') {
            window.gtag('consent', action, consent);
        }
    }

    public setConsent(): void | GoogleConsent {
        this.updateDatalayerConsent('default', this.defaultConsent);

        if (Boolean(this.cookieConsent)) {
            const googleConsent = this.mapConsent(this.cookieConsent);
            this.updateDatalayerConsent('update', googleConsent);
            return googleConsent;
        }
    }

    private addGtagScript() {
        this.renderer = this.rendererFactory.createRenderer(null, null);

        const gtagScript = this.renderer.createElement('script');
        gtagScript.type = 'text/javascript';
        gtagScript.text = `
            window.dataLayer = window.dataLayer || [];

            function gtag() {
              dataLayer.push(arguments);
            }
         `;
        this.document.head.appendChild(gtagScript);
    }

    /**
     * Creates the iframe necessary for google tag manager
     * @private
     */
    private addTagManagerScript(): void {
        this.addGtagScript();
        this.setConsent();

        this.renderer = this.rendererFactory.createRenderer(null, null);

        const textIframe = this.renderer
            .createText(`<iframe src="//www.googletagmanager.com/ns.html?id=${this.configuration.gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
        this.document.head.appendChild(textIframe);

        this.addGoogleTagManager(window, this.document, 'script', 'dataLayer', this.configuration.gtmId);
    }

    /**
     * Adds the google tag manager script to the DOM
     * @param window the window object
     * @param document the document object
     * @param script the script tag
     * @param layer the dataLayer name
     * @param id the google tag manger Id
     * @private
     */
    private addGoogleTagManager(window: Window, document, script: string, layer: string, id: string): void {
        if (typeof window === 'undefined') {
            return;
        }
        window[layer] = window[layer] || [];
        window[layer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f: HTMLScriptElement = document.getElementsByTagName(script)[0];
        const j: HTMLScriptElement = document.createElement(script);
        const dl: string = layer !== 'dataLayer' ? '&l=' + layer : '';
        j.async = true;
        j.src = '//www.googletagmanager.com/gtm.js?id=' + id + dl;
        f.parentNode.insertBefore(j, f);
    }

    /**
     * push the new data onto the dataLayer
     * @param data
     * @private
     */
    private sendToDatalayer(data: ApiDatalayerInterface): void {
        const datalayer = this.getDatalayer();

        if (typeof datalayer === 'undefined') {
            return;
        }
        datalayer.push(data);
    }
}
