import { Component, Input } from '@angular/core';
import { SmartTipsImageEnum } from '../../smart-tips-image.enum';
import { SmartTipsEnum } from '../../smart-tips.enum';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-tanken-fehler-summary',
    templateUrl: './tanken-fehler-summary.component.html',
})
export class TankenFehlerSummaryComponent {
    @Input() public smartTipsCarousel: boolean = false;

    public smartTipsEnum = SmartTipsEnum;
    public appRoutesEnum = AppRoutesEnum;
    public smartTipsImageEnum = SmartTipsImageEnum;
}
